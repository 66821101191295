import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './home.css';
import { parseISO, format, formatISO, parse } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../App';

const Archive = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [names, setNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    role: '',
    approval: '',
    flightNumber: '',
    departureOrArrival: '',
    priority: '',
    flightTime: '',
    statusType: '',
    destination: '',
    createdOn: '',
    numOfEscorts:'',

    
  });


  const { role: userRole } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    fetchNames();
    
    
    const intervalId = setInterval(fetchData, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);// Fetch names when component mounts
  }, []);


  useEffect(() => {
    console.log('Search term:', searchTerm);
  console.log('Original data length:', originalData.length);
    if (searchTerm.trim() === '') {
      setData([...originalData]);
    } else {
      // Restore original data if search term is cleared
      search(searchTerm);
    }
  }, [searchTerm, originalData]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/ParliamentariansForm?SortBy=flightTime&IsDecsending=true&PageSize=10000&IsExpired=true', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
     
  
      const flightData = response.data;
      const dataArray = Array.isArray(flightData) ? flightData : [flightData];
      const convertedData = dataArray.map(item => {
        const userName = item.createdBy ? item.createdBy.userName : ''; // Extract userName
        return {
          ...item,
          userName, // Add userName to item
          role: convertRole(item.role),
          departureOrArrival: convertDepartureOrArrival(item.departureOrArrival),
          priority: convertPriority(item.priority),
          statusType: convertStatusType(item.statusType),
        };
      });
      setData(convertedData);
      setOriginalData(convertedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login'); // Redirect to login if unauthorized
      }
    }
  };
  

  const fetchNames = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/People/names', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const nameList = response.data.map(item => item.name);
      setNames(nameList);
      
    } catch (error) {
      console.error('Error fetching names:', error);
    }
  };
  

  const search = async (searchTerm) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/ParliamentariansForm/search', {
        params: { searchTerm },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const flightData = response.data;
      const dataArray = Array.isArray(flightData) ? flightData : [flightData];
      const convertedData = dataArray.map(item => {
        const userName = item.createdBy ? item.createdBy.userName : '';
        return {
          ...item,
          userName,
          role: convertRole(item.role),
          departureOrArrival: convertDepartureOrArrival(item.departureOrArrival),
          priority: convertPriority(item.priority),
          statusType: convertStatusType(item.statusType),
        };
      });
      setData(convertedData);
    } catch (error) {
      console.error('Error fetching search data:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login'); // Redirect to login if unauthorized
      }
    }
  };
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    search(searchTerm);
  };

  const convertRole = (value) => {
    switch (value) {
      case 0: return 'برلماني'; // Assuming 0 means parliamentarian
      case 1: return 'ضيف'; // Assuming 1 means guest
      default: return '';
    }
  };

 

  const convertDepartureOrArrival = (value) => {
    switch (value) {
      case 0: return 'مغادرة'; // Assuming 0 means parliamentarian
      case 1: return 'قدوم'; // Assuming 1 means guest
      default: return '';
    }
  };

  const convertPriority = (value) => {
    switch (value) {
      case 0: return 'تصوير جوازات';
      case 1: return 'توقف تفتيش';
      case 2: return 'بدون توقف';
      default: return 'Unknown';
    }
  };

  const convertStatusType = (value) => {
    switch (value) {
      case 0: return 'انتظار';
      case 1: return 'جاء';
      case 2: return 'متأخر';
      case 3: return 'لم ياتي';
      default: return 'Unknown';
    }
  };

  const handleDelete = async (id) => {
    if (userRole === 'Admin') {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/api/ParliamentariansForm/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchData();
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  const handleEditClick = (item) => {
    if (userRole === 'Admin' || userRole === 'User') {
      const flightDate = new Date(item.flightTime);

      // Ensure flightDate is valid
      if (isNaN(flightDate.getTime())) {
        console.error('Invalid date:', item.flightTime);
        return;
      }
  
      // Convert to local time
      const localDate = flightDate.toISOString().slice(0, 10); // YYYY-MM-DD
      const localTime = flightDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // HH:mm
  
      const formattedFlightTime = `${localDate}T${localTime}`;
      setFormData({
        ...item,
        departureOrArrival: item.departureOrArrival === 'مغادرة' ? 0 : 1,
        priority: item.priority === 'تصوير جوازات' ? 0 : (item.priority === 'توقف تفتيش' ? 1 : 2),
        statusType: item.statusType === 'انتظار' ? 0 : (item.statusType === 'جاء' ? 1 : (item.statusType === 'متأخر' ? 2 : 3)),
        flightTime: formattedFlightTime,
        destination: item.destination,
        
      });
      setShowForm(true);
    }
  };

  const convertToLocalTime = (utcDateTimeString) => {
    if (utcDateTimeString) {
      const utcDate = parseISO(utcDateTimeString);
      return format(utcDate, 'yyyy-MM-dd HH:mm:ss');
    }
    return '';
  };

  const getRowColor = (flightTime) => {
    const currentTime = new Date();
    const flightDateTime = new Date(flightTime);
  
    if (isNaN(flightDateTime.getTime())) return ''; // Handle invalid dates
  
    const fifteenMinutesBefore = new Date(flightDateTime.getTime() - 15 * 60 * 1000);
  
    if (currentTime > flightDateTime) {
      return 'red'; // Flight time has passed
    } else if (currentTime > fifteenMinutesBefore) {
      return 'green'; // Within 15 minutes before flight time
    } else {
      return ''; // Default color
    }
  };


  // const getCellClass = (value) => {
  //   switch (value) {
  //     case 'قدوم': // Arrival
  //       return 'arrival';
  //     case 'مغادرة': // Departure
  //       return 'departure';
  //     default:
  //       return '';
  //   }
  // };
  
  
  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoleChange = (role) => {
    setFormData({ ...formData, role, approval: '' });
  };

  const handlePriorityChange = (priority) => {
    setFormData({ ...formData, priority });
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (userRole === 'Admin' || userRole === 'User') {
      try {
        const token = localStorage.getItem('token');
        const flightTimeUTC = formData.flightTime
    ? new Date(formData.flightTime).toISOString()  // Converts to ISO 8601 string with 'Z'
    : '';

        const convertedFormData = {
          id: formData.id,
          name: formData.name,
          role: formData.role === 'ضيف' ? 1 : 0,
          approval: formData.approval || '',
          flightNumber: formData.flightNumber,
          departureOrArrival: formData.departureOrArrival,
          priority: formData.priority === 'تصوير جوازات' ? 0 : (formData.priority === 'توقف تفتيش' ? 1 : 2),
          flightTime: flightTimeUTC,
          statusType: formData.statusType,
          destination: formData.destination,
        };

        console.log('Submitting data:', convertedFormData);

        if (convertedFormData.id) {
          await axios.put(`/api/ParliamentariansForm/${convertedFormData.id}`, convertedFormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              
            },
          });
        } else {
          await axios.post('/api/ParliamentariansForm', convertedFormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              
            },
          });
        }
        
        fetchData();
        setShowForm(false);
        setFormData({
          id: '',
          name: '',
          role: '',
          approval: '',
          flightNumber: '',
          departureOrArrival: '',
          priority: '',
          flightTime: '',
          statusType: '',
          destination: '',
          
         
        });
      } catch (error) {
        console.error('Error submitting form:', error.response ? error.response.data : error.message);
      }
    }
  };
  
  const handleFormCancel = () => {
    setShowForm(false);
    setFormData({
      id: '',
          name: '',
          role: '',
          approval: '',
          flightNumber: '',
          departureOrArrival: '',
          priority: '',
          flightTime: '',
          statusType: '',
          destination: '',
          
         
    });
 
    
  };

  return (
    <div className="home">
      <h2>الارشيف</h2>
      <div className="controls">
       
      </div>
      <form className="search-bar" onSubmit={handleSearchSubmit}>
        <input 
          type="text" 
          placeholder="Search..." 
          value={searchTerm} 
          onChange={handleSearchChange} 
        />
        <button className='search-icon-button' type="submit">
        <i className="fas fa-search search-icon"></i>
        </button>
      </form>
      {data.length > 0 && (
        <table className="home-table">
          <thead>
            <tr>
              <th>ت</th>
              
              <th>الاسم</th>
              <th>الصفة</th>
              <th>الوجهة</th>
              
              <th>رقم الرحلة</th>
              <th>قدوم / مغادرة</th>
              <th>الاجراء</th>
              <th>الحالة</th>
              <th>تاريخ الاضافة</th>
              <th>وقت الرحلة</th>
              
              <th>اسم المدخل</th>
              <th>ملاحظات</th>
              {/* <th>تعديل / حذف</th> */}
            </tr>
          </thead>
          <tbody>
          {data.map((item , index) => (
                <tr key={index} className={(item.flightTime)}>

                <td>{index + 1}</td>
                
                <td>{item.name}</td>
                <td>{item.role}</td>
                <td>{item.destination}</td>
                
                <td>{item.flightNumber}</td>
                {/* <td className={getCellClass(item.departureOrArrival)}> */}
                <td>
                   {item.departureOrArrival}
                </td>
                <td>{item.priority}</td>
                <td>{item.statusType}</td>
                <td>{convertToLocalTime(item.createdOn)}</td>
                <td>{convertToLocalTime(item.flightTime)}</td>
                <td>{item.userName}</td>
                <td>{item.approval}</td>
                
                {/* <td>
                  {(userRole === 'Admin' || userRole === 'User') && (
                    <>
                      <button
                        className="edit-button"
                        onClick={() => handleEditClick(item)}
                      >
                        تحرير
                      </button>
                      {userRole === 'Admin' && (
                        <button
                          className="delete-button"
                          onClick={() => handleDelete(item.id)}
                        >
                          حذف
                        </button>
                      )}
                    </>
                  )}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {showForm && (
        <div className="form-overlay">
          <div className="form-container">
            <form onSubmit={handleFormSubmit}>
              <div>
              <label htmlFor="name">الاسم</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                list="name-options"
                required
              />
              <datalist id="name-options">
                {names.map((name, index) => (
                  <option key={index} value={name} />
                ))}
              </datalist>
            </div>
              <div>
                <label>الصفة:</label>
                <div className="role-buttons">
                  <button
                    type="button"
                    onClick={() => handleRoleChange('ضيف')}
                    className={`btn ${formData.role === 'ضيف' ? 'selected' : ''}`}
                  >
                    ضيف
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRoleChange('برلماني')}
                    className={`btn ${formData.role === 'برلماني' ? 'selected' : ''}`}
                  >
                    برلماني
                  </button>
                </div>
              </div>
              {formData.role === 'ضيف' && (
                <div>
                  <label>الملاحظات</label>
                  <input
                    type="text"
                    name="approval"
                    value={formData.approval}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              )}
               <div>
                <label> الوجهة:</label>
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>رقم الرحلة:</label>
                <input
                  type="text"
                  name="flightNumber"
                  value={formData.flightNumber}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>قدوم / مغادرة:</label>
                <div className="departure-arrival-buttons">
                  <button
                    type="button"
                    onClick={() => setFormData({ ...formData, departureOrArrival: 1 })}
                    className={`btn ${formData.departureOrArrival === 1 ? 'selected' : ''}`}
                  >
                    قدوم
                  </button>
                  <button
                    type="button"
                    onClick={() => setFormData({ ...formData, departureOrArrival: 0 })}
                    className={`btn ${formData.departureOrArrival === 0 ? 'selected' : ''}`}
                  >
                    مغادرة
                  </button>
                </div>
              </div>
              <div>
                <label>الاجراء:</label>
                <div className='Priority'>
                <button
                type="button"
                className={`btn ${formData.priority === 0 ? 'selected' : ''} `}
                onClick={() => handlePriorityChange(0)}
              >
                تصوير جوازات
              </button>
              <button
                type="button"
                className={`btn ${formData.priority === 1 ? 'selected' : ''}`}
                onClick={() => handlePriorityChange(1)}
              >
                توقف تفتيش
              </button>
              <button
                type="button"
                className={`btn ${formData.priority === 2 ? 'selected' : ''}`}
                onClick={() => handlePriorityChange(2)}
              >
                بدون توقف
              </button>
              </div>
           
              </div>
              <div>
                <label>وقت الرحلة:</label>
                <input
                  type="datetime-local"
                  name="flightTime"
                  value={formData.flightTime}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label> الحالة: </label>
                <select
                  name="statusType"
                  value={formData.statusType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">اختر...</option>
                  <option value="0">انتظار</option>
                  <option value="1">جاء</option>
                  <option value="2">متأخر</option>
                  <option value="3">لم ياتي</option>
                </select>
              </div>
              <div className="form-buttons">
                <button type="submit">اضافة</button>
                <button type="button" onClick={handleFormCancel}>الغاء</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Archive;

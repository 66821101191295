import React, { useState, useContext } from 'react';
import axios from 'axios';
import styles from './Login.module.css'; // Ensure your CSS module file exists
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../App';
import { FaEye , FaEyeSlash } from "react-icons/fa";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const { setRole } = useContext(UserContext);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        if (error) setError(''); // Clear error when the user starts typing
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (error) setError(''); // Clear error when the user starts typing
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible); // Step 2: Toggle the password visibility
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('/api/account/login', {
                userName: username,
                password: password,
            });

            console.log('Login response:', response.data); // Debug log

            const user = response.data;

            if (user && user.token) {
                // Store token and role in local storage
                localStorage.setItem('token', user.token);
                localStorage.setItem('role', user.userRole);
                console.log('Setting role:', user.userRole); // Debug log
                setRole(user.userRole);

                // Redirect based on role
                if (user.userRole === 'Admin' || user.userRole === 'User') {
                    navigate('/layout/profile');
                } else if (user.userRole === 'Viewer') {
                    navigate('/viewer');
                } else {
                    setError('Invalid user role');
                }
            } else {
                setError('Invalid username or password');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('Invalid username or password');
        }
    };

    return (
        <div style={{
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            fontFamily: 'Noto Kufi Arabic, sans-serif',
            direction: 'rtl',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
        }}>
            <div className={styles.container}>
                <header>تسجيل الدخول</header>
                <form onSubmit={handleSubmit}>
                    <div className={styles.form}>
                        <div className="details personal">
                            <div className={styles.fields}>
                                <div className={styles['input-field']}><br />
                                    <label>اسم المستخدم</label>
                                    <input
                                        type="text"
                                        placeholder="أدخل اسم المستخدم"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        required
                                    />
                                </div>
                                <div className={styles['input-field']}>
                                    <label>كلمة المرور</label>
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder="أدخل كلمة المرور"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                    />
                                      <span onClick={togglePasswordVisibility} className={styles.eyeIcon}>
                                      {passwordVisible ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                                     </span>
                                </div>
                            </div>
                            {error && <p className={styles.error}>{error}</p>}
                            <div className={styles['button-container']}>
                                <button type="submit" className="submit">
                                    <span className={styles.btnText}>تسجيل الدخول</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './home.css';
import { UserContext } from '../App';

const Names = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    stage: '',
    inactive: false,
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const { role: userRole } = useContext(UserContext);

  useEffect(() => {
    if (userRole !== 'Admin' && userRole !== 'User') {
      return;
    }
    fetchData();
  }, [userRole]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults(data);
    } else {
      fetchSearchResults(searchQuery);
    }
  }, [searchQuery, data]);

  const fetchData = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/People', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Fetch Data Response:', response.data); // Log the response data
      if (Array.isArray(response.data)) {
        setData(response.data);
        setSearchResults(response.data); // Initialize search results with fetched data
      } else {
        console.error('Error: response.data is not an array');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchSearchResults = async (query) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/People/search?searchTerm=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Search Results Response:', response.data); // Log the search response data
      if (Array.isArray(response.data)) {
        setSearchResults(response.data);
      } else {
        console.error('Error: search response data is not an array');
      }
    } catch (error) {
      console.error('Error searching names:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/People/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEditClick = (item) => {
    setFormData(item);
    setShowForm(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStatusChange = (status) => {
    setFormData({ ...formData, inactive: status === 'inactive' });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const stageRegex = /^[A-Za-z0-9\s]+$/;
    if (!stageRegex.test(formData.stage)) {
      setError('Please enter the stage in English numbers.');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      if (formData.id) {
        await axios.put(`/api/People/${formData.id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await axios.post('/api/People', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      fetchData();
      setShowForm(false);
      setFormData({
        id: '',
        name: '',
        stage: '',
        inactive: false,
        password: '',
      });

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleFormCancel = () => {
    setShowForm(false);
    setFormData({
      id: '',
      name: '',
      stage: '',
      inactive: false,
      password: '',
    });
    setError('');
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const displayedData = searchQuery ? searchResults : data;

  return (
    <div className="home">
      <h2>اسماء النواب</h2>
      <div className="controls">
        <button onClick={() => setShowForm(true)}>اضافة</button>
      </div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button type="submit" className="search-icon-button">
          <i className="fas fa-search search-icon"></i>
        </button>
      </div>
      {displayedData.length > 0 && (
        <table className="home-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>الاسم</th>
              <th>الدورة</th>
              <th>فعال / غير فعال</th>
              <th>العمليات</th>
            </tr>
          </thead>
          <tbody>
            {displayedData.map((item , index) => (
              <tr key={item.id}>
                <td>{index+1}</td>
                <td>{item.name}</td>
                <td>{item.stage}</td>
                <td>{item.inactive ? 'غير فعال' : 'فعال'}</td>
                <td>
                  <button
                    className="edit-button"
                    onClick={() => handleEditClick(item)}
                  >
                    تعديل
                  </button>
                  {userRole === 'Admin' && (
                  <button
                    className="delete-button"
                    onClick={() => handleDelete(item.id)}
                  >
                    حذف
                  </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {showForm && (
        <div className="form-overlay">
          <div className="form-container2">
            <form onSubmit={handleFormSubmit}>
              <div>
                <label>الاسم: </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>الدورة: </label>
                <input
                  type="text"
                  name="stage"
                  value={formData.stage}
                  onChange={handleInputChange}
                  required
                />
                {error && <p className="error-message">{error}</p>} {/* Display error message */}
              </div>
              <div>
                <label>الحالة: </label>
                <div className="active-buttons">
                  <button
                    type="button"
                    onClick={() => handleStatusChange('active')}
                    className={`btn ${!formData.inactive ? 'selected' : ''}`}
                  >
                    فعال
                  </button>
                  <button
                    type="button"
                    onClick={() => handleStatusChange('inactive')}
                    className={`btn ${formData.inactive ? 'selected' : ''}`}
                  >
                    غير فعال
                  </button>
                </div>
              </div><br />
              <div className="form-buttons">
                <button type="submit">{formData.id ? 'تحديث' : 'اضافة'}</button>
                <button type="button" onClick={handleFormCancel}>الغاء</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Names;

import React, { createContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Users from './pages/Users';
import Layout from './components/Layout';
import Login from './pages/Login';
import Names from './pages/Names';
import Viewer from './pages/Viewer';
import Profile from './pages/profile';
import Archive from './pages/Archive';  // Ensure this component exists
import axios from 'axios';


axios.defaults.baseURL = 'https://par.go.iq';

// Create a context for user role
export const UserContext = createContext();

const App = () => {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const token = localStorage.getItem('token');
        const userRole = localStorage.getItem('role');
        console.log('Token:', token);
        console.log('User Role:', userRole);

        if (token && userRole) {
          setRole(userRole); // Directly use the role from localStorage
        } else {
          setRole(null); // If no token or role, treat as unauthenticated
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
        setRole(null); // Handle error or default role
      } finally {
        setLoading(false);
      }
    };

    fetchRole();
  }, []);

  // Route protection component
  const ProtectedRoute = ({ element, allowedRoles }) => {
    if (loading) return <div>Loading...</div>; // or a spinner

    if (role === null) {
      // User is not authenticated
      return <Navigate to="/" />;
    }

    return allowedRoles.includes(role) ? element : <Navigate to="/" />;
  };

  const RedirectBasedOnRole = () => {
    if (loading) return <div>Loading...</div>; // or a spinner

    console.log('Role during redirection:', role); // Debugging line

    if (role === 'Admin' || role === 'User') {
      return <Navigate to="/layout/home" />;
    } else if (role === 'Viewer') {
      return <Navigate to="/Viewer" />;
    } else {
      return <Navigate to="/" />;
    }
  };

  return (
    <UserContext.Provider value={{ role, setRole }}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Viewer" element={<ProtectedRoute element={<Viewer />} allowedRoles={['Viewer' , 'Admin' , 'User']} />} />
          <Route path="/layout/*" element={<ProtectedRoute element={<Layout />} allowedRoles={['Admin', 'User']} />}>
            <Route
              path="home"
              element={<ProtectedRoute element={<Home />} allowedRoles={['Admin', 'User']} />}
            />
            <Route
              path="names"
              element={<ProtectedRoute element={<Names />} allowedRoles={['Admin' ,'User']} />}
            />
            <Route
              path="profile"
              element={<ProtectedRoute element={<Profile />} allowedRoles={['Admin' ,'User']} />}
            />
              <Route
              path="Archive"
              element={<ProtectedRoute element={<Archive />} allowedRoles={['Admin' ,'User']} />}
            />
            <Route
              path="users"
              element={<ProtectedRoute element={<Users />} allowedRoles={['Admin']} />}
            />
          </Route>
          <Route path="*" element={<RedirectBasedOnRole />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

export default App;

import React, { useState, useRef, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import axios from 'axios';
import './home.css';

const Profile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const[fullName, setFullName] = useState('');
  const [imageUrl , setImageUrl] = useState('');
  const [photo, setPhoto] = useState('https://via.placeholder.com/150');
  const [croppedPhoto, setCroppedPhoto] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const fileInputRef = useRef(null);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        const profileResponse = await axios.get('/api/UserProfile', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        const { firstName, lastName, imageUrl , fullName } = profileResponse.data;
        setFirstName(firstName);
        setLastName(lastName);
        setFullName(fullName);
        setImageUrl(imageUrl);
  
        const imageResponse = await axios.get(`/api/UserProfile/image?imgUrl=${encodeURIComponent(imageUrl)}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
          responseType: 'blob',
        });
  
        if (imageResponse.data) {
          const fetchedImageUrl = URL.createObjectURL(imageResponse.data);
          setPhoto(fetchedImageUrl);
        } else {
          setPhoto('https://via.placeholder.com/150');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
  
    fetchProfile();
  }, []);
  
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhoto(URL.createObjectURL(file));
      setCroppedPhoto(null);
    }
  };

  const handleCrop = () => {
    if (cropper) {
      setCroppedPhoto(cropper.getCroppedCanvas().toDataURL());
    }
  };const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      
      // Create a new FormData object
      const formData = new FormData();
      formData.append('firstName', firstName || '');
      formData.append('lastName', lastName || '');
  
      if (croppedPhoto) {
        // Convert the base64 image to a Blob and append it to the FormData
        const response = await fetch(croppedPhoto);
        const blob = await response.blob();
        formData.append('profileImage', blob, 'profile.jpg'); // 'profile.jpg' is the file name
      }
  
      // Send the PUT request with the FormData
      await axios.put('/api/UserProfile/updateprofile', formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });
  
      alert('تم تحديث الحساب بنجاح');
      setShowForm(false);
    } catch (error) {
      console.error('Error updating profile:', error);
  
      if (window.confirm('امتدادات الصور المسموحة فقط JPG.\nهل تريد تحويل صورتك الى JPG ؟')) {
        window.open('https://cloudconvert.com/', '_blank');
      }
    }
  };
  
  
  const triggerFileInput = () => {
    setPhoto('');
    fileInputRef.current.click();
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <img src={croppedPhoto || photo} alt="Profile" className="profile-photo" />
        <h2 className='profilename'>{`${fullName}`}</h2>
        <button className="editprofile-button" onClick={() => setShowForm(true)}>
          تعديل الحساب
        </button>
      </div>

      {showForm && (
        <div className="form-overlay">
          <div className="formprofile-container">
            <form onSubmit={handleSubmit}>
              <div className="profile">
                <img src={croppedPhoto || photo} alt="Profile" className="profile-photo1" />
                <button type="button" className="file-input-button" onClick={triggerFileInput}>
                  Choose File
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handlePhotoChange}
                  className="file-input"
                  style={{ display: 'none' }}
                />
                {photo !== 'https://via.placeholder.com/150' && !croppedPhoto && (
                  <div>
                    <Cropper
                      src={photo}
                      style={{ height: 400, width: '100%' }}
                      aspectRatio={1}
                      guides={false}
                      cropBoxResizable={true}
                      cropBoxMovable={true}
                      onInitialized={(instance) => setCropper(instance)}
                    />
                    <button className="crop-button" onClick={handleCrop}>
                      Crop
                    </button>
                  </div>
                )}
                <div className="input-group">
                  <label>First Name: </label>
                  <input type="text" value={firstName} onChange={handleFirstNameChange} />
                </div>
                <div className="input-group">
                  <label>Last Name: </label>
                  <input type="text" value={lastName} onChange={handleLastNameChange} />
                </div>
                <div className='form1-buttons'>
                  <button type="submit">تحديث</button>
                  <button type="button" onClick={() => setShowForm(false)}>الغاء</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
